import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../auth/UserContext';
import useAPIRequest from '../../hooks/useAPIRequest';
import TeamMemberResource from '../../api/resources/team/TeamMemberResource';
import get from 'lodash/get';
import UserProfileButton from './UserProfileButton';
import { Modal } from '../../components/dialogs';
import UserData from './UserData';
import styled from 'styled-components';
import { ThemeContext, themes } from '../../contexts/theme/ThemeContext';
import { Accordion } from 'semantic-ui-react';
import MonthlySalariesResource from '../../api/resources/salaries/MonthlySalariesResource';
import { subMonths } from 'date-fns';
import { dateToString } from '../../utils/dateUtils';
import YearSalaryChart, {
  formatBGNSummary,
} from './financial-stats/YearSalaryChart';
import { roundToTwo } from '../../utils/numberUtils';

const UserProfileModal = styled(Modal)`
  height: 900px;
  max-height: 90%;
  background-color: #00a650 !important;
  border-radius: 20px !important;
  box-shadow: none;
  font-family: MarkOT-Medium, sans-serif;
`;

const FinancialStatsAccordion = styled(Accordion)`
  font-family: MarkOT-Medium, sans-serif;
  color: #fff;
  padding: 0 30px 20px 30px;
  font-size: 1.2em;
  text-transform: lowercase;

  & p,
  & .ui.form > p {
    margin-top: 0;
    margin-bottom: 0;
  }

  &.ui.accordion .title:not(.ui) {
    font-size: 1.05em;
    font-family: MarkOT-Medium, sans-serif;
  }
`;

const UserProfile = () => {
  const { user, userProfilePictureUrl } = UserContext.useContainer();
  const [open, setOpen] = useState(false);

  const { theme } = useContext(ThemeContext);
  const {
    data: teamMemberResponse,
    performRequest: getTeamMember,
  } = useAPIRequest(TeamMemberResource.findById);

  const {
    data: ownHolidaysResponse,
    performRequest: getOwnHolidays,
  } = useAPIRequest(TeamMemberResource.fetchOwnHolidaysData);

  const {
    data: ownSalaryStatsResponse,
    performRequest: getOwnSalaryStats,
  } = useAPIRequest(MonthlySalariesResource.getOwnSalaryStats);

  useEffect(() => {
    const teamMemberId = get(user, 'teamMemberId');
    if (teamMemberId) {
      getTeamMember(teamMemberId);
      getOwnHolidays();
    }
  }, [user, getTeamMember, getOwnHolidays]);

  useEffect(() => {
    getOwnSalaryStats(dateToString(subMonths(new Date().setDate(15), 1)));
  }, [getOwnSalaryStats]);

  const refetchTeamMember = () => {
    const teamMemberId = get(user, 'teamMemberId');
    if (teamMemberId) {
      getTeamMember(teamMemberId);
    }
  };

  return (
    <>
      {teamMemberResponse?.data && (
        <UserProfileModal
          className="user-profile"
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          trigger={
            <UserProfileButton
              teamMember={teamMemberResponse?.data}
              onClick={() => setOpen(true)}
              userProfilePictureUrl={userProfilePictureUrl}
            />
          }
        >
          <UserData
            teamMember={teamMemberResponse?.data}
            refetchTeamMember={refetchTeamMember}
          />
          <FinancialStatsAccordion
            exclusive={false}
            inverted={theme === themes.dark}
            panels={[
              {
                title: 'Financial stats',
                content: {
                  content: (
                    <div>
                      <div
                        style={{
                          paddingLeft: '28px',
                          marginBottom: '20px',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>
                          <p>
                            monthly wage:{' '}
                            {ownSalaryStatsResponse?.data.averageSalary
                              ? formatBGNSummary(
                                  ownSalaryStatsResponse?.data.averageSalary
                                )
                              : ''}
                          </p>
                          <p>
                            growth:{' '}
                            {ownSalaryStatsResponse?.data.sixMonthsSalaryGrowth
                              ? formatBGNSummary(
                                  ownSalaryStatsResponse?.data
                                    .sixMonthsSalaryGrowth
                                )
                              : ''}
                          </p>
                          <p>
                            productivity rate:{' '}
                            {teamMemberResponse?.data.productivity}
                          </p>
                          <p>
                            images per month:{' '}
                            <span
                              style={{ textTransform: 'uppercase' }}
                            >{`${roundToTwo(
                              ownSalaryStatsResponse?.data.averageImgCount ?? 0
                            )} CGI`}</span>
                          </p>
                        </div>
                        <div style={{ textAlign: 'right' }}>
                          <p>
                            Holiday Spent: {ownHolidaysResponse?.data.spentDays}
                          </p>
                          <p>
                            Holiday Left: {ownHolidaysResponse?.data.leftDays}
                          </p>
                          <p>Toil: {'   '}</p>
                        </div>
                      </div>
                      {ownSalaryStatsResponse?.data && (
                        <div
                          style={{
                            borderRadius: '20px',
                            padding: '5px',
                            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                          }}
                        >
                          <YearSalaryChart
                            data={ownSalaryStatsResponse?.data.salariesData}
                            averageSalary={
                              ownSalaryStatsResponse?.data.averageSalary
                            }
                            month={subMonths(new Date().setDate(15), 1)}
                          />
                        </div>
                      )}
                    </div>
                  ),
                },
              },
            ]}
          />
        </UserProfileModal>
      )}
    </>
  );
};

export default UserProfile;
