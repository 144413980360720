import styled from 'styled-components';

type CalendarWeeksRowProps = {
  numberOfDays: number;
  resourceCellWidth: string;
  bottomBorder?: boolean;
  rowsTemplate?: string;
  dateCellSize?: number;
  borderTop?: boolean;
  inverted?: boolean;
};

type DateLabelProps = {
  borderRight?: boolean;
  isLastDayOfWeek?: boolean;
  inverted: boolean;
};

type CalendarWrapperProps = {
  inverted?: boolean;
};

export const CalendarWrapper = styled.div<CalendarWrapperProps>`
  ${(props) =>
    props.inverted
      ? `*:not(input):not(.dropdown) {
      border-color: #353535 !important;
  }`
      : ''}
`;

export const CalendarWeeksRow = styled.div<CalendarWeeksRowProps>`
  display: grid;
  grid-template-columns: ${(props) => props.resourceCellWidth} repeat(
      ${(props) => props.numberOfDays},
      ${(props) => `${props?.dateCellSize}px` || '1fr'}
    );
  grid-auto-flow: dense;
  ${(props) => props.borderTop && 'border-top: 1px solid rgba(34,36,38,.1);'};
  border-left: 1px solid rgba(34, 36, 38, 0.1);
  ${(props) =>
    props.rowsTemplate && `grid-rows-template: ${props.rowsTemplate};`}
`;

const headerCellStyles = `
  font-size: 0.85em;
  padding: 0.2em 0.1em;
  transition: background .1s ease,color .1s ease;
  text-align: center;
  border-top: 1px solid rgba(34,36,38,.1);
`;

const cellStyles = `
  padding: .2em .7em;
  transition: background .1s ease,color .1s ease;
  font-size: 0.94em;
`;

type WeekLabelProps = {
  columnStart: number;
  span: number;
  isLastWeek?: boolean;
  inverted: boolean;
};

type OccupationRateWeekLabelProps = {
  columnStart: number;
  span: number;
  isLastWeek?: boolean;
  backgroundColor: string;
};

export const WeekLabel = styled.div<WeekLabelProps>`
  font-size: 0.85em;
  font-weight: bold;
  grid-column-start: ${(props) => props.columnStart};
  grid-column-end: span ${(props) => props.span};
  border-right: ${(props) => (props.isLastWeek ? '1' : '2')}px solid
    rgba(34, 36, 38, 0.1);
  background-color: ${(props) => (props.inverted ? '#252525' : 'inherit')};
  ${headerCellStyles}
`;

export const OccupationRateWeekLabel = styled.div<OccupationRateWeekLabelProps>`
  font-size: 0.85em;
  font-weight: bold;
  grid-column-start: ${(props) => props.columnStart};
  grid-column-end: span ${(props) => props.span};
  border-right: ${(props) => (props.isLastWeek ? '1' : '2')}px solid
    rgba(34, 36, 38, 0.1);
  background-color: ${(props) => props.backgroundColor};
  color: #fff;
  ${headerCellStyles}
`;

export const DateLabelCell = styled.div<DateLabelProps>`
  ${headerCellStyles}
  font-size: 0.6em;
  min-width: 22px;
  background-color: ${(props) => (props.inverted ? '#252525' : 'inherit')};
  border-right: ${(props) => (props.isLastDayOfWeek ? 2 : 1)}px solid
    rgba(34, 36, 38, 0.1);
`;

type HeaderCellProps = {
  inverted: boolean;
};

export const ResourceHeaderCell = styled.div<HeaderCellProps>`
  ${headerCellStyles}
  border-left: none;
  border-right: 1px solid rgba(34, 36, 38, 0.1);
  background-color: ${(props) => (props.inverted ? '#252525' : 'inherit')};
`;

export const GridCell = styled.div`
  display: contents;
  position: relative;
`;

type ResourceCellProps = {
  editable?: boolean;
};

export const ResourceCell = styled.div<ResourceCellProps>`
  ${cellStyles};
  text-overflow: ellipsis;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  ${(props) => (props.editable ? 'cursor: pointer;' : '')}
  border-right: 1px solid rgba(34, 36, 38, 0.1);
  font-family: MarkOT-Medium, Open Sans, sans-serif;
`;

export const Day = styled.div`
  display: contents;
  ${cellStyles};
`;

type EventProps = {
  color: string;
  diagonalColor: string;
  span?: number;
  columnStart?: number;
  rowStart?: number;
  width?: number;
  isPending: boolean;
  isRejected: boolean;
  editable: boolean;
  hasEventInfoComponent: boolean;
};

export const Event = styled.div<EventProps>`
  grid-row-start: ${(props) => props.rowStart};
  grid-column-start: ${(props) => props.columnStart};
  grid-column-end: span ${(props) => props.span};
  background-color: ${(props) => props.color};
  width: ${(props) => props.width};
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => (props.isPending ? '#FF0000' : '#fff')};
  white-space: nowrap;
  padding: 0.05em 0.5em;
  border-radius: 0.28571429rem;
  margin: 0.1em 0.4em;
  font-size: 0.95em;
  cursor: ${(props) =>
    props.editable || props.hasEventInfoComponent ? 'pointer' : 'auto'};
  max-height: 2em;
  height: 1.5em;
  z-index: 1;
  text-decoration: ${(props) => (props.isRejected ? 'line-through' : 'none')};
  font-family: MarkOT-Medium, Open Sans, sans-serif;
  position: relative;
  pointer-events: auto;
  background: ${(props) =>
    `repeating-linear-gradient(-45deg, ${props.diagonalColor}, ${props.diagonalColor} 7px, ${props.color} 7px, ${props.color} 14px )`};
`;

type MilestoneProps = {
  columnStart: number;
  rowStart: number;
};

export const Milestone = styled.div<MilestoneProps>`
  grid-column-start: ${(props) => props.columnStart};
  grid-row-start: ${(props) => props.rowStart};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 0.3em;
  font-size: 0.85em;
  cursor: pointer;
`;

type FillerDivProps = {
  height?: string;
  columnStart?: number;
  rowStart?: number;
  backgroundColor?: string;
  minHeight?: string;
  borderLeft?: boolean;
  borderBottom?: boolean;
  borderRight?: boolean;
  isLastDaysOfWeek?: boolean;
  bottomDivider?: boolean;
};

export const GridFiller = styled.div<FillerDivProps>`
  grid-column-start: ${(props) => props.columnStart ?? 'auto'};
  grid-row-start: ${(props) => props.rowStart ?? 'auto'};
  height: ${(props) => props.height ?? 'auto'};
  min-height: ${(props) => props.minHeight ?? 'auto'};
  background-color: ${(props) => props.backgroundColor ?? '#fff'};
  min-width: 22px;
  ${(props) =>
    props.borderRight &&
    `border-right: ${
      props.isLastDaysOfWeek ? 2 : 1
    }px solid rgba(34, 36, 38, 0.1);`}
  ${(props) => props.borderLeft && 'border-left: 1px solid rgba(34,36,38,.1);'};
  ${(props) =>
    props.borderBottom &&
    `border-bottom: ${
      props.bottomDivider ? '3' : '1'
    }px solid rgba(34,36,38,.1);`};
`;
