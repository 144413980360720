import React, { RefObject, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { Confirm, Modal } from '../dialogs';
import { ButtonSizeProps } from '../../types/forms';
import { AxiosError } from 'axios';

type Props = {
  callback: () => void | Promise<void>;
  confirmText?: string;
  buttonSize?: ButtonSizeProps;
  disabled?: boolean;
  mountNode: RefObject<HTMLDivElement | null>;
};

export const DeleteButton = <Button negative content="Delete" size="huge" />;

const DeleteAction = (props: Props) => {
  const [error, setError] = useState<null | AxiosError>(null);

  const {
    callback,
    confirmText = 'Are you sure you want to delete the object?',
    buttonSize = 'huge',
    disabled = false,
    mountNode,
  } = props;

  const handleSubmit = async () => {
    try {
      await callback();
    } catch (e) {
      setError(e);
    }
  };

  return (
    <>
      <Confirm
        content={confirmText}
        trigger={
          <Button
            negative
            disabled={disabled}
            content="Delete"
            size={buttonSize}
          />
        }
        onSubmit={handleSubmit}
        mountNode={mountNode}
      />
      {error && (
        <Modal
          defaultOpen
          header={`Error: ${error.message}`}
          content={error.response?.data.error.message || error.message}
          actions={[
            {
              key: 'dismiss',
              content: 'Dismiss',
              negative: true,
              onActionClick: () => setError(null),
              size: 'tiny',
            },
          ]}
          mountNode={mountNode}
        />
      )}
    </>
  );
};

export default DeleteAction;
