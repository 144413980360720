import React, { RefObject } from 'react';
import { Button, Checkbox } from 'semantic-ui-react';
import { TeamMember } from '../types';
import Table from '../../../components/tables/Table';
import Header from '../../../components/tables/Header';
import Row from '../../../components/tables/Row';
import HeaderCell from '../../../components/tables/HeaderCell';
import Body from '../../../components/tables/Body';
import Cell from '../../../components/tables/Cell';
import {
  allPermissions,
  FULL_ACCESS_PERMISSION,
  FullAccessPermissions,
  SALARIES_PERMISSION,
} from '../permission';
import { Id } from '../../../types/base';
import AuthResource from '../../../api/resources/AuthResource';
import useAPIRequest from '../../../hooks/useAPIRequest';
import Loader from '../../../components/Loader';
import { UserContext } from '../../auth/UserContext';
import get from 'lodash/get';
import { Modal } from '../../../components/dialogs';
import { hasPermission } from '../../auth/utils';

type Props = {
  selected: TeamMember[] | TeamMember;
  reload: (resetSelection?: boolean) => Promise<void>;
  mountNode: RefObject<HTMLDivElement | null>;
};

const AccessRow = ({
  teamMember,
  handlePermissionUpdate,
}: {
  teamMember: TeamMember;
  handlePermissionUpdate: (
    id: Id,
    option: string,
    value: boolean | undefined
  ) => Promise<void>;
}) => {
  const { user } = UserContext.useContainer();

  return (
    <Row key={`${teamMember.id}--view-access`}>
      <Cell>{teamMember.name}</Cell>
      {allPermissions.map((permission) => (
        <Cell key={`${teamMember.id}--${permission}`} textAlign="center">
          <Checkbox
            as="span"
            checked={teamMember.permissions?.includes(permission)}
            disabled={
              (teamMember.permissions?.includes(FULL_ACCESS_PERMISSION) &&
                FullAccessPermissions.includes(permission) &&
                permission !== FULL_ACCESS_PERMISSION) ||
              (permission === SALARIES_PERMISSION &&
                !hasPermission(user, SALARIES_PERMISSION))
            }
            onClick={(event, { checked }) =>
              handlePermissionUpdate(teamMember.userId, permission, checked)
            }
          />
        </Cell>
      ))}
    </Row>
  );
};

const ViewAccess = (props: Props) => {
  const { selected, reload, mountNode } = props;

  const apiRequest = useAPIRequest<[Id, string, boolean]>(
    AuthResource.updatePermissions
  );

  const { user, performRequest: reloadUser } = UserContext.useContainer();

  const handlePermissionUpdate = async (
    id: Id,
    option: string,
    value: boolean | undefined
  ) => {
    await apiRequest.performRequest(id, option, !!value);
    if (id === get(user, 'id') && option === FULL_ACCESS_PERMISSION && !value) {
      await reloadUser();
    } else {
      await reload(false);
    }
  };

  return (
    <Modal
      closeIcon
      mountNode={mountNode}
      content={
        <>
          <Loader active={apiRequest.loading} />
          <Table>
            <Header>
              <Row>
                <HeaderCell collapsing>Name</HeaderCell>
                {allPermissions.map((permission) => (
                  <HeaderCell key={`${permission}-header`}>
                    {permission.split(/(?=[A-Z])/).join(' ')}
                  </HeaderCell>
                ))}
              </Row>
            </Header>
            <Body>
              {Array.isArray(selected) ? (
                selected.map((teamMember) => (
                  <AccessRow
                    key={`${teamMember.id}-access-row`}
                    teamMember={teamMember}
                    handlePermissionUpdate={handlePermissionUpdate}
                  />
                ))
              ) : (
                <AccessRow
                  teamMember={selected}
                  handlePermissionUpdate={handlePermissionUpdate}
                />
              )}
            </Body>
          </Table>
        </>
      }
      trigger={
        <Button
          size={Array.isArray(selected) ? 'huge' : 'large'}
          content="Access"
        />
      }
    />
  );
};

export default ViewAccess;
