import React, { ComponentProps, ReactNode } from 'react';
import { ProjectItem } from '../types';
import { getCurrencyUnit, ITEM_VALUE, NEGATIVE_VALUE } from '../constants';
import Row from '../../../components/tables/Row';
import Cell from '../../../components/tables/Cell';
import {
  PROJECT_ITEM_SUBTYPE_IMAGE,
  PROJECT_ITEM_SUBTYPE_OTHER,
  PROJECT_ITEM_SUBTYPE_VIDEO,
} from '../../settings/keys';

type Props = {
  items: ProjectItem[];
  subset: boolean;
  context?: 'invoice' | 'project' | 'proposal';
};

type FooterRowProps = {
  items: ProjectItem[];
  label: ReactNode;
  rowProps?: ComponentProps<typeof Row>;
  context?: 'invoice' | 'project' | 'proposal';
};

export const getSum = (items: ProjectItem[]) => {
  return items.reduce(
    (prev, curr) => prev + +curr.items * +curr.costPerItem,
    0
  );
};

export const formatBudgetCurrency = (unit: string, budget: number) => {
  return `${unit !== 'lv' ? `${unit}` : ''} ${budget} ${
    unit === 'lv' ? ` ${unit}` : ''
  }`;
};

const FooterRow = ({
  items,
  label,
  rowProps = {},
  context,
}: FooterRowProps) => {
  const totalItems = getSum(items);
  const totalDays = items.reduce((prev, curr) => prev + +curr.items, 0);
  const unit = items.length ? getCurrencyUnit(items[0].projectCurrency) : '';

  return items.length ? (
    <Row {...rowProps}>
      <Cell
        colSpan={context === 'project' ? 3 : context === 'proposal' ? 1 : 2}
      >
        {' '}
        <strong>{label}</strong>{' '}
      </Cell>
      <Cell>{totalDays}</Cell>
      <Cell />
      <Cell>{formatBudgetCurrency(unit, totalItems)}</Cell>
    </Row>
  ) : null;
};

export const calculateProjectBudget = (items: ProjectItem[]) => {
  const projectItems = items.filter((item) => item.type === ITEM_VALUE);

  const negatives = items.filter((item) => item.type === NEGATIVE_VALUE);

  return getSum(projectItems) - getSum(negatives);
};

const TableFooter = (props: Props) => {
  const { items, subset, context } = props;
  const projectItems = items.filter((item) => item.type === ITEM_VALUE);

  const videos = projectItems.filter(
    (item) => item.subType === PROJECT_ITEM_SUBTYPE_VIDEO
  );
  const images = projectItems.filter(
    (item) => item.subType === PROJECT_ITEM_SUBTYPE_IMAGE
  );
  const others = projectItems.filter(
    (item) => item.subType === PROJECT_ITEM_SUBTYPE_OTHER
  );

  const negatives = items.filter((item) => item.type === NEGATIVE_VALUE);

  const budget = getSum(projectItems) - getSum(negatives);

  const unit = items.length ? getCurrencyUnit(items[0].projectCurrency) : '';

  const totalImages = images.reduce((prev, curr) => prev + +curr.items, 0);
  const totalVideos = videos.reduce((prev, curr) => prev + +curr.items, 0);
  const totalOthers = others.reduce((prev, curr) => prev + +curr.items, 0);

  return (
    <>
      <FooterRow
        items={projectItems}
        label={`${subset ? 'Selected Items' : 'Items'}:
        ${
          images.length
            ? `${totalImages} Image${totalImages > 1 ? 's' : ''}${
                totalImages > 0 && totalVideos > 0 ? ',' : ''
              }`
            : ''
        } ${videos.length ? `${totalVideos}sec video` : ''}${
          totalVideos > 0 && totalOthers > 0 ? ',' : ''
        }
        ${
          others.length
            ? `${totalOthers} Other${totalOthers > 1 ? 's' : ''}`
            : ''
        }
        `}
        context={context}
      />
      <FooterRow
        items={negatives}
        rowProps={{ negative: true }}
        label="Negatives"
        context={context}
      />
      {items.length ? (
        <Row>
          <Cell
            colSpan={context === 'project' ? 3 : context === 'proposal' ? 1 : 2}
          >
            <strong>{subset ? 'Selected Budget' : 'Total Budget'}</strong>{' '}
          </Cell>
          <Cell />
          <Cell />
          <Cell>
            <strong>{formatBudgetCurrency(unit, budget)}</strong>
          </Cell>
        </Row>
      ) : null}
    </>
  );
};

export default TableFooter;
