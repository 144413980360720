import React from 'react';
import styled from 'styled-components';
import { GRADIENT_DEFAULT_COLOR } from '../../../contexts/theme/ThemeContext';
import tinycolor from 'tinycolor2';
import BlueGreenLabel from '../../../components/BlueGreenLabel';
import { formatSummary } from './AverageSalaryComponentGraph';

const Wrapper = styled.div`
  margin-top: 1rem;
  background-color: #1e1e1e;
  border-radius: 20px !important;

  .internal-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    & > div {
      width: 250px;
      height: 100px;
    }

    text-align: center;
    height: 100%;
    padding: 10px;
    border-radius: 20px !important;
    background: ${`linear-gradient(45deg, ${GRADIENT_DEFAULT_COLOR} 0%, ${tinycolor(
      GRADIENT_DEFAULT_COLOR
    )
      .setAlpha(tinycolor(GRADIENT_DEFAULT_COLOR).getAlpha() / 2)
      .toRgbString()} 50%, ${tinycolor(GRADIENT_DEFAULT_COLOR)
      .setAlpha(0)
      .toRgbString()} 100%)`};
  }
`;

const AdditionalStats = ({
  averageSalariesResponse,
  imagesPerPersonResponse,
  averageImageCostResponse,
}: {
  averageSalariesResponse: {
    data: {
      seniorAverageSalary: number;
      juniorAverageSalary: number;
      intermediateAverageSalary: number;
      performanceBonusRatio: number;
      sixMonthsSalaryGrowth: number;
    };
  };
  imagesPerPersonResponse: {
    data: number;
  } | null;
  averageImageCostResponse: {
    data: number;
  } | null;
}) => {
  return (
    <Wrapper>
      <div className="internal-container">
        <BlueGreenLabel
          label={'Senior Monthly Salary'}
          value={formatSummary(
            averageSalariesResponse?.data.seniorAverageSalary
          )}
        />
        <BlueGreenLabel
          label={'Intermediate Monthly Salary'}
          value={formatSummary(
            averageSalariesResponse?.data.intermediateAverageSalary
          )}
        />
        <BlueGreenLabel
          label={'Junior Monthly Salary'}
          value={formatSummary(
            averageSalariesResponse?.data.juniorAverageSalary
          )}
        />
        {imagesPerPersonResponse && (
          <BlueGreenLabel
            label={'Monthly Images per Person'}
            value={`${new Intl.NumberFormat('en-GB', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            }).format(imagesPerPersonResponse.data)} CGI`}
          />
        )}
        <BlueGreenLabel
          label={'Six Months Salary Growth'}
          value={formatSummary(
            averageSalariesResponse?.data.sixMonthsSalaryGrowth
          )}
        />
        <BlueGreenLabel
          label={'Performance Bonus Ratio'}
          value={new Intl.NumberFormat('en-GB', {
            style: 'percent',
          }).format(averageSalariesResponse?.data.performanceBonusRatio)}
        />
        {averageImageCostResponse && (
          <BlueGreenLabel
            label={'Average Image Cost'}
            value={formatSummary(averageImageCostResponse.data)}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default AdditionalStats;
